<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-7 col-12">Audio Call Campaign Reports<span class="log-header-sublabel ms-3">- {{
      campaigndetails.co2 ? campaigndetails.co2 : "N/A" }}</span></div>
    <div class="
            col-lg-5 col-12
            text-end
            d-flex
            justify-content-end
            custom-flex-cloumn-mob
          ">
          <div class="d-flex align-items-center">
          <InputSwitch class="me-3" v-model="ApiLoadingSwitch" @change="switchLoadingStatus()" />
              <button type="button" title="Refresh" class="btn custom-outline-view-btn me-3"
                @click="callcampaignreport({ co1: this.routeParam })">
                <i class="pi pi-refresh call-btn-color"></i>
              </button>
            </div>
      <!-- <div class="service-plan-box-outer">
        <div class="d-flex justify-content-between align-items-center">
          <div class="call-label">Call Dialed : </div>
          <div class="call-count ps-1">
            N/A
          </div>
        </div>
      </div> -->
      <!-- <div class="service-plan-box-outer ms-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="call-label">Call Connected : </div>
          <div class="call-count ps-1">
            {{ this.callconnectedvalue ? this.callconnectedvalue : "0" }}
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <div class="ticket-history-box-outer mb-3" style="min-height: 118px;">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-12">
        <div class="customer-label-group mb-2">
          <label class="form-label">Scheduled at Date & Time</label>
          <div class="from-label-value">
            {{ format_timestamp(campaigndetails.co12) }}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <div class="customer-label-group mb-2">
          <label class="form-label">Scheduled for Date & Time</label>
          <div class="from-label-value">
            {{ format_timestamp(campaigndetails.co5) }}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <div class="customer-label-group mb-0">
          <div v-if="campaigndetails.cl3 != null && campaigndetails.cl3 != ''"><audio controls controlsList="download"
              style="height: 35px !important;">
              <source
                :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/callcampaign/' + campaigndetails.cl3"
                type="audio/mpeg" />
            </audio>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-12">
        <div class="customer-label-group mb-2">
          <button v-if="campaigndetails.cl11 == 1" type="button" class="btn modal-bulk-next-btn my-1 me-2"
            disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl9
    }}</span>- {{
        campaigndetails.cl10 }}</button>
          <button v-if="campaigndetails.cl14 == 1" type="button" class="btn modal-bulk-next-btn my-1 me-2"
            disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl12
    }}</span>- {{
        campaigndetails.cl13 }}</button>
          <button v-if="campaigndetails.cl17 == 1" type="button" class="btn modal-bulk-next-btn my-1 me-2"
            disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl15
    }}</span>- {{
        campaigndetails.cl16 }}</button>
          <button v-if="campaigndetails.cl20 == 1" type="button" class="btn modal-bulk-next-btn my-1 me-2"
            disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl18
    }}</span>- {{
        campaigndetails.cl19 }}</button>
          <button v-if="campaigndetails.cl23 == 1" type="button" class="btn modal-bulk-next-btn my-1 me-2"
            disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl21
    }}</span>- {{
        campaigndetails.cl22 }}</button>
          <button v-if="campaigndetails.cl26 == 1" type="button" class="btn modal-bulk-next-btn my-1 me-2"
            disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl24
    }}</span>- {{
        campaigndetails.cl25 }}</button>
          <button v-if="campaigndetails.cl29 == 1" type="button" class="btn modal-bulk-next-btn my-1 me-2"
            disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl27
    }}</span>- {{
        campaigndetails.cl28 }}</button>
          <button v-if="campaigndetails.cl32 == 1" type="button" class="btn modal-bulk-next-btn my-1 me-2"
            disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl30
    }}</span>- {{
        campaigndetails.cl31 }}</button>
          <button v-if="campaigndetails.cl35 == 1" type="button" class="btn modal-bulk-next-btn my-1 me-2"
            disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl33
    }}</span>- {{
        campaigndetails.cl34 }}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="company-section-outer" style="height: calc(100vh - 275px);">
    <div class="row g-3">
      <div class="col-lg-4 col-md-4 col-12">
        <div class="feedback-chart-outer pb-3">
          <div class="d-flex justify-content-between">
            <div class="chart-label">Voters Click Report</div>
          </div>
          <div v-if="checkEmptyDataCampaign">
            <div style="position: relative;min-height: 390px;">
              <div v-if="!showloadergenderreport">
                <Chart type="doughnut" :data="chartDataCampaign" :options="campaignOptions" responsive="true" />
              </div>
              <div v-if="showloadergenderreport">
                <div class="custom-modal-spinner-loader">
                  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div style="position: relative;min-height: 350px;" class="d-flex align-items-center justify-content-center">
              <div>
                No data found
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12" v-if="logsstatusflag">
        <div class="card border-success shadow p-3 mb-5 bg-white rounded">
          <div class="card-body">
            <div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="msg  in chartDataValue" :key="msg">
                  <div class="d-flex justify-content-between align-items-center"><span><button type="button"
                        class="btn modal-bulk-next-btn" disabled><span class="me-2 fw-bold" v-if="msg.button_number">{{
                          msg.button_number
                          }} -</span> {{
                        msg.label }}</button></span><span class="fw-bold">{{
                      msg.count
                      }}</span></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import { useRoute } from "vue-router";
import moment from 'moment';
export default {
  data() {
    return {
      chartDataCampaign: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ["#f79c31", "#ee3f44", "#5eb4d7", "#c29851", "#b76b70"],
            hoverBackgroundColor: ["#f79c31", "#ee3f44", "#5eb4d7", "#c29851", "#b76b70"],
          },
        ],
      },
      campaignOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
      },
      chartDataValue: [],
      ApiLoadingSwitch: false,
      checkEmptyDataCampaign: true,
      showloadergenderreport: false,
      logsstatusflag: false,
      campaigndetails: '',
      client_info: '',
      voterstorageimgpath: '',
      callconnectedvalue: '',
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  mounted() {
    this.loading = true;
    this.showloadergenderreport = true;
    const route = useRoute();
    this.routeParam = route.params.campaignId;
    this.callcampaignreport({ co1: this.routeParam });
    if (localStorage.user) {
      this.client_info = JSON.parse(localStorage.client_info);
      this.voterstorageimgpath = this.client_info.maa24;
    }
  },
  beforeUnmount() {
    clearInterval(this.timer4);
  },
  methods: {
    callcampaignreport(item) {
      this.ApiService.callcampaignreport(item).then((data) => {
        this.campaigndetails = data.campaign_details;
        if (data.success == true) {
          this.chartDataCampaign = {
            labels: data.labels,
            datasets: [
              {
                data: data.data,
                backgroundColor: ["#f79c31", "#ee3f44", "#5eb4d7"],
                hoverBackgroundColor: ["#f79c31", "#ee3f44", "#5eb4d7"],
              },
            ],
          };
          this.chartDataValue = data.datatable;
          this.callconnectedvalue = data.callconnectedcount;
          this.showloadergenderreport = false;
          this.logsstatusflag = true;
        } else {
          this.checkEmptyDataCampaign = false;
          this.showloadergenderreport = false;
          this.logsstatusflag = false;
        }
      });
    },
    switchLoadingStatus() {
      if (this.ApiLoadingSwitch == true) {
        this.timer4 = window.setInterval(() => {
          this.callcampaignreport({ co1: this.routeParam });
        }, 5000);
      } else {
        clearInterval(this.timer4);
      }
    },
    format_timestamp(value) {
      if (value) {
        var localTime = new Date(value * 1000).toUTCString();
        return moment.utc(localTime).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return '-';
      }
    },
  },
};
</script>
<style scoped>
.feedback-chart-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
}

.feedback-chart-outer .chart-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  padding: 16px;
}

.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 520px; */
}

.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.log-header-sublabel {
  font-family: "AcuminPro-SemiBold";
  font-size: 17px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.service-plan-box-outer {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
  padding: 6px 10px;
}

.service-plan-box-outer .call-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  user-select: none;
}

.service-plan-box-outer .call-count {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>